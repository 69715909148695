import axios from "axios";
const url = "api/v1/sel/";
const zone = Intl.DateTimeFormat().resolvedOptions().timeZone
class SellerService {

  static getSaleData(username) {
    let formData = {
      user: username
    }
    axios.defaults.headers.post["Content-Type"] = "application/json";

    return axios.post(url + "lst/xmrws", formData,
      { headers: { "Content-Type": "application/json" } }
    );
  }

  static getCases(user) {
    let formData = {
      user: user
    }
    axios.defaults.headers.post["Content-Type"] = "application/json";

    return axios.post(url + "sfcdws", formData,
      { headers: { "Content-Type": "application/json" } }
    );
  }

  static getData(user) {
    let formData = {
      user: user
    }
    axios.defaults.headers.post["Content-Type"] = "application/json";

    return axios.post(url + "stschm", formData,
      { headers: { "Content-Type": "application/json" } }
    );
  }

  static NewRevenueTarget(user) {
    let formData = {
      user: user
    }
    axios.defaults.headers.post["Content-Type"] = "application/json";

    return axios.post(url + "rvthm", formData,
      { headers: { "Content-Type": "application/json" } }
    );
  }

  static RenewalRevenueTarget(user) {
    let formData = {
      user: user
    }
    axios.defaults.headers.post["Content-Type"] = "application/json";

    return axios.post(url + "rrvthm", formData,
      { headers: { "Content-Type": "application/json" } }
    );
  }

  static TotalCustomersTarget(user) {
    let formData = {
      user: user
    }
    axios.defaults.headers.post["Content-Type"] = "application/json";

    return axios.post(url + "tctthm", formData,
      { headers: { "Content-Type": "application/json" } }
    );
  }

  static getSalesPipeline(user) {
    let formData = {
      user: user
    }
    axios.defaults.headers.post["Content-Type"] = "application/json";
    return axios.post(url + "pldxs", formData,
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }
    );
  }

  static getRocksAssigned(user) {

    let formData = {
      user: user,
      zone: zone
    }
    axios.defaults.headers.post["Content-Type"] = "application/json";
    return axios.post(url + "gdsrc", formData,
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }
    );
  }

  static getGiftUser(user) {
    let formData = {
      user: user
    }
    axios.defaults.headers.post["Content-Type"] = "application/json";
    return axios.post(url + "rwrgt", formData,
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }
    );
  }
  static interactBot(user, msg) {
    let formData = {
      user: user,
      msg: msg
    }
    axios.defaults.headers.post["Content-Type"] = "application/json";
    return axios.post(url + "caih", formData,
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }
    );

  }

  static staticBot(user) {
    let formData = {
      user: user
    }
    axios.defaults.headers.post["Content-Type"] = "application/json";
    return axios.post(url + "caiq", formData,
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }
    );

  }
  static insertPMElite(user, start, end, cpm, botw, wdyn, mscale, tgls) {
    let formData = {
      user: user,
      start: start,
      end: end,
      cpm: cpm,
      botw: botw,
      wdyn: wdyn,
      mscale: mscale,
      tgls: tgls
    }
    axios.defaults.headers.post["Content-Type"] = "application/json";
    return axios.post(url + "sgd", formData,
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }
    );

  }

  static getGoals(user) {
    let formData = {
      user: user
    }
    axios.defaults.headers.post["Content-Type"] = "application/json";
    return axios.post(url + "ggd", formData,
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }
    );

  }
  static IsGoals(user) {
    let formData = {
      user: user
    }
    axios.defaults.headers.post["Content-Type"] = "application/json";
    return axios.post(url + "gft", formData,
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }
    );

  }
  static updateGoals(user, code, tgls) {
    let formData = {
      user: user,
      code: code,
      tgls: tgls
    }
    axios.defaults.headers.post["Content-Type"] = "application/json";
    return axios.post(url + "upgd", formData,
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }
    );

  }
  static dailyActivityforDay(user) {
    let formData = {
      user: user
    }
    axios.defaults.headers.post["Content-Type"] = "application/json";
    return axios.post(url + "cgls", formData,
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }
    );

  }
  static getDailyHabits(user) {
    let formData = {
      user: user
    }
    axios.defaults.headers.post["Content-Type"] = "application/json";
    return axios.post(url + "geryg", formData,
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }
    );

  }

  static getQuests(user, status) {
    let formData = {
      user: user,
      status: status
    }
    axios.defaults.headers.post["Content-Type"] = "application/json";
    return axios.post("/api/v1/eqrt/gdrtc", formData,
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }
    );

  }
  static getQuestsWithoutImg(user) {
    let formData = {
      user: user
    }
    axios.defaults.headers.post["Content-Type"] = "application/json";
    return axios.post("/api/v1/gxw/brqs", formData,
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }
    );

  }

  static getValidateImages(user, coin) {
    let formData = {
      user: user,
      coin: coin
    }
    axios.defaults.headers.post["Content-Type"] = "application/json";
    return axios.post("/api/v1/qrwd/sdrwgd", formData,
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }
    );
  }

  static getImagebyItem(user, idApprove) {
    let formData = {
      user: user,
      idApprove: idApprove
    }
    axios.defaults.headers.post["Content-Type"] = "application/json";
    return axios.post("/api/v1/gxw/svimgpr", formData,
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }
    );
  }

  static approveImg(user, approveId, status, lang) {
    let formData = {
      user: user,
      approveId: approveId,
      status: status,
      lang: lang

    }
    axios.defaults.headers.post["Content-Type"] = "application/json";
    return axios.post("/api/v1/qrwd/sdaprd", formData,
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }
    );
  }
  static insertQuest(user, zone, avatar,logo) {
    let formData = new FormData();
    formData.append("user", user);
    formData.append("zone", JSON.stringify(zone));
    if (avatar) {
      formData.append("image", avatar, avatar.name);
    }
    if (logo) {
      formData.append("logo", logo, logo.name);
    }
    return axios.post("/api/v1/eqrt/gdsrc", formData,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
  }

  static updateAirdropbyID(user, zone) {
    let formData = {
      user: user,
      zone: zone
    }
    return axios.put("/api/v1/eqrt/gdsrc", formData,
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }
    );
  }

  static insertQuestItem(user, items) {
    let formData = {
      user: user,
      zone: items
    }
    return axios.put("/api/v1/eqrt/gdsrc", formData,
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }
    );

  }

  static insertRewardsImgUser(user, questId, itemId, imagen) {
    let formData = new FormData();
    formData.append("user", user);
    formData.append("questId", questId);
    formData.append("itemId", itemId);
    if (imagen) {
      formData.append("imagen", imagen, imagen.name);
    }
    return axios.put("/api/v1/qrwd/strd", formData,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );

  }

  static insertRewardsUser(user, questId, itemId, url) {
    let formData = {
      user: user,
      questId: questId,
      itemId: itemId,
      url: url
    }
    return axios.post("/api/v1/qrwd/strd", formData,
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }
    );
  }

  static getItemDetail(user) {
    let formData = {
      user: user
    }
    return axios.post("/api/v1/qrwd/gtrd", formData,
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }
    );
  }

  static getQuestProgress(user) {
    let formData = {
      user: user
    }
    return axios.post("/api/v1/qrwd/gpgd", formData,
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }
    );
  }

  static getExpbyUser(user) {
    let formData = {
      user: user
    }
    return axios.post("/api/v1/qrwd/gtrw", formData,
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }
    );
  }

  static insertItemStock(user, zone, avatar) {
    let formData = new FormData();
    formData.append("user", user);
    formData.append("zone", JSON.stringify(zone));
    if (avatar) {
      formData.append("image", avatar, avatar.name);
    }
    return axios.post("/api/v1/strd/wrxs", formData,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
  }
  static getItemsStock(user) {
    let formData = {
      user: user
    }
    return axios.post("/api/v1/strd/gdstr", formData,
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }
    );
  }

  static getTypesItem(user) {
    let formData = {
      user: user
    }
    return axios.post("/api/v1/strd/gtstpd", formData,
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }
    );
  }

  static InsertBuyItem(user, zonestd) {
    let formData = {
      user: user,
      zonestd: zonestd
    }
    return axios.post("/api/v1/strd/gtinfd", formData,
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }
    );
  }

  static getItempurchased(user) {
    let formData = {
      user: user
    }
    return axios.post("/api/v1/strd/gstitst", formData,
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }
    );
  }
  static getItemBuy(user, ppos) {
    let formData = {
      user: user,
      pos: ppos
    }
    return axios.post("/api/v1/aprv/gstrfapr", formData,
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }
    );
  }
  static approveBuyItem(user, statusId, status, lang) {
    let formData = {
      user: user,
      statusId: statusId,
      status: status,
      lang: lang
    }
    return axios.post("/api/v1/aprv/sndrwd", formData,
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }
    );
  }

  static DeleteQuest(user, questId) {
    let formData = {
      user: user,
      questId: questId
    }
    return axios.post("/api/v1/aprv/sdrvx", formData,
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }
    );
  }
  static DeleteItem(user, itemId) {
    let formData = {
      user: user,
      itemId: itemId
    }
    return axios.post("/api/v1/gxw/dlxstr", formData,
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }
    );
  }

  static insertLgbyUser(user, lang) {
    let formData = {
      user: user,
      lang: lang
    }
    return axios.post("/api/v1/gxw/svlng", formData,
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }
    );
  }
  static getFeedbakcs(user) {
    let formData = {
      user: user
    }
    return axios.post("/api/v1/gxw/sdcbk", formData,
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }
    );
  }
  static getComments(user, questid) {
    let formData = {
      user: user,
      questid: questid
    }
    return axios.post("/api/v1/gxw/gecombq", formData,
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }
    );
  }

  static getCategories(user) {
    let formData = {
      user: user
    }
    return axios.post("/api/v1/gxw/tsrc", formData,
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }
    );
  }
  static getTopUser(userid, start, end) {
    let formData = {
      userid: userid,
      start: start,
      end: end
    }
    return axios.post("/api/v1/ftr/spgrd", formData,
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }
    );
  }

  static getTMSWeek(user) {
    let formData = {
      user: user
    }
    return axios.post("/api/v1/gdx/lmx", formData,
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }
    );
  }



  static UpdatedailyActivityforDay(user, list) {
    let formData = {
      user: user,
      list: list
    }
    axios.defaults.headers.post["Content-Type"] = "application/json";
    return axios.post(url + "ucglt", formData,
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }
    );

  }

  static saveProfile(user, profile) {
    let formData = {
      user,
      profile
    }
    return axios.post("/api/v1/gdx/pfl", formData,
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }
    );
  }

  static questCompleted(user) {
    let formData = {
      user
    }
    return axios.post("/api/v1/gdx/pfchgc", formData,
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }
    );
  }

  static getProfile(user) {
    let formData = {
      user
    }
    return axios.post("/api/v1/gdx/gprf", formData,
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }
    );
  }

  static sendClaimJewels(user, info) {
    let formData = {
      user: user,
      info: info
    }
    return axios.post("/api/v1/strd/tsx/sxjwr", formData,
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }
    );
  }


  static getTotalJewelsAvailable(user) {
    let formData = {
      user: user
    }
    return axios.post("/api/v1/strd/tsx/pxlk", formData,
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }
    );
  }




}
export default SellerService;
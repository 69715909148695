<template>
  <div>
     <div class="text-center">
           <h4 class="font-weight-bolder d-block d-sm-block d-md-none">{{ quest.quest.options.title }}</h4>
          </div>
    <b-tabs content-class="mt-2" pills  :fill="['sm','xs'].includes($store.getters['app/currentBreakPoint'])?true:false">
      <b-tab disabled v-if="['sm','xs'].includes($store.getters['app/currentBreakPoint'])?false:true">
        <template #title>
          <h4 class="font-weight-bolder text-white d-none d-md-block" style="margin-top: -5px">
            {{ quest.quest.options.title }}
          </h4>
        </template>
      </b-tab>
      <b-tab active>
        <template #title>
          <span>All Quests</span>
        </template>
        <div v-if="cambio == 0" class="text-center text-success">
          <b-spinner style="width: 3rem; height: 3rem" class="mr-1" />
        </div>
        <div v-else>
          <b-card>
            <b-row class="match-height">
              <b-col
                xl="4"
                lg="4"
                md="6"
                sm="12"
                v-for="(item, i) in quest.quest.options.items"
                :key="i"
              >
                <item
                  :item="item"
                  :idquest="idquest"
                  :rewards="deliver[i]"
                  v-if="cambio == 1"
                />
              </b-col>
            </b-row>
          </b-card>
        </div>
      </b-tab>
      <b-tab>
        <template #title>
          <span>Pending Review</span>
        </template>
        <b-card v-if="pending.length>0">
          <b-row class="match-height">
              <b-col
                xl="4"
                lg="4"
                md="6"
                sm="12"
                v-for="(item, d) in pending"
              :key="d" 
              >
                <item :item="item" :idquest="idquest" :rewards="rewardpending[d]" />
              </b-col>
           
          </b-row>
        </b-card>
        <b-card v-else style="min-height:60vh;">
          <section class="h-100" style="min-height:60vh;">
  <header class="container h-100" style="min-height:60vh;">
    <div class="d-flex align-items-center justify-content-center h-100" style="min-height:60vh">
      <div class="d-flex flex-column">
        <h4 class="text align-self-center p-1">No Pending quest</h4>
        <h6 class="text align-self-center p-2">Check out some of the daily quests and complete them.</h6>
       </div>
    </div>
  </header>
</section>
        </b-card>
      </b-tab>
      <b-tab>
        <template #title>
          <span >Completed</span>
        </template>
          <b-card v-if="completed.length>0">
          <b-row class="match-height">
             
              <b-col
                xl="4"
                lg="4"
                md="6"
                sm="12"
                
              v-for="(item, d) in completed"
              :key="d" 
              >
                <item :item="item" :idquest="idquest" :rewards="rewardcompleted[d]" />
              </b-col>
         
          </b-row>
        </b-card> 
        <b-card v-else style="min-height:60vh;">
          <section class="h-100" style="min-height:60vh;">
  <header class="container h-100" style="min-height:60vh;">
    <div class="d-flex align-items-center justify-content-center h-100" style="min-height:60vh">
      <div class="d-flex flex-column">
        <h4 class="text align-self-center p-1">No completed quest</h4>
        <h6 class="text align-self-center p-2">Kindly check the daily quest out and completed them to get rewards.</h6>
       </div>
    </div>
  </header>
</section>
        </b-card> 
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import { BRow, BCol, BSpinner, BTabs, BTab, BCard,BContainer } from "bootstrap-vue";
import Item from "@/components/Item.vue";
import SellerService from "@/services/SellerService";
export default {
  components: { BRow, BCol, Item, BSpinner, BTabs, BTab, BCard,BContainer},
  data() {
    const dataquest = JSON.parse(localStorage.getItem("dataquest"));
    return {
      quest: dataquest,
      idquest: dataquest.quest._id,
      rewards: [],
      cambio: 0,
      deliver: [],
      pending:[],
      completed:[],
      rewardpending:[],
      rewardcompleted:[]
    };
  },
  mounted() {
    this.getItemCompleted();
  },
  methods: {
    getItemCompleted: async function () {
      const d = (
        await SellerService.getItemDetail(
          JSON.parse(localStorage.getItem("auth")).discordId
        )
      ).data;
      this.rewards = d;
      this.deliver = [];
      for (
        let index = 0;
        index < this.quest.quest.options.items.length;
        index++
      ) {
        this.deliver.push(
          this.getDataByFilter(
            this.idquest,
            this.quest.quest.options.items[index].id
          )
        );
      }
      this.pending=[];
      this.completed=[];
      this.rewardpending=[];
      this.rewardcompleted=[];
       for (let x=0;x< this.quest.quest.options.items.length;x++) {
       var dx= this.quest.quest.options.items[x]
       if(this.deliver[x][0] && this.deliver[x][0].status == 'pending'){
        
        this.pending.push(dx);
        this.rewardpending.push(this.deliver[x])
       }else if(this.deliver[x][0] && this.deliver[x][0].status == 'completed'){
        
        this.completed.push(dx);
        this.rewardcompleted.push(this.deliver[x])
       }
       
    }
      this.cambio = 1;
    },
    getDataByFilter: function (questId, itemId) {
      let arreglo = [];
      try {
        arreglo = this.rewards.filter(
          (value) => value.itemQuestId == itemId && value.questId == questId
        );
      } catch (error) {}

      return arreglo;
    },
  },
};
</script>
<style>
.dark-layout .nav-pills .nav-item .nav-link.active {
  background: linear-gradient(
    87.2deg,
    rgba(164, 136, 255, 0.8) 2.33%,
    rgba(74, 44, 174, 0.83) 51.02%,
    #27088e 94.9%
  );
  border: transparent;
}
</style>